<template>
    <main class="flex-grow-1 align-content-center">
        <div class="container">
            <div style="width: 0;" class="d-none d-lg-block mt-5">
            <h1 class="">Support</h1>
            </div>
            <h1 class="d-block d-lg-none mt-4">Support</h1>
            
            <div>
                We are in the process of setting up a formal bug tracking and feedback system. While this is in development please get in touch at hello@.com
            </div>
        </div>
    </main>
</template>

<script>
export default {

}
</script>

<style>

</style>